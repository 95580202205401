.login {
  width: 100%;
  height: 100vh;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  background-image: url(../../images/background.svg);
  background-size: cover;
  background-position: top center;
}
