body:has(.container) {
  overflow: revert;
}

.container table {
  overflow: scroll;
}

.container table,
.container th,
.container td {
  border: 1px solid black;
  border-collapse: collapse;

  font-size: 10pt;
}

.container table {
  width: 100%;
}

.container td {
  padding: 4px 8px;
}

.hide {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  gap: 16px;
  margin: 16px 0;
}

@media print {
  .hide {
    display: none;
  }
}

.details {
  display: flex;
  justify-content: space-between;
  align-items: flex-end;
}
