@import url('https://fonts.googleapis.com/css?family=Nunito&display=swap');

/*
 * We have `--chrome-height` because we have to offset the
 * browser chrome height for mobile. We have 2 values (1 default, 1 react-native)
 * because the chrome is always visible when viewing in
 * the browser, and is always hidden when on react-native.
 */
:root {
  --chrome-height: 130px;
}

:root .react-native {
  --chrome-height: 0px;
}

body {
  overscroll-behavior: none;
}

a {
  text-decoration: none;
}

/*main {*/
  /*display: flex;*/
  /*height: 100vh;*/
/*}*/

/*aside {*/
  /*flex: 0 0 11rem;*/
  /*background-color: #2d99d0;*/
  /*color: #fff;*/
  /*display: flex;*/
  /*flex-direction: column;*/
/*}*/

/*section {*/
  /*flex: 1 1 auto;*/
  /*overflow: scroll;*/
/*}*/

/*aside h2 {*/
  /*text-align: center;*/
  /*margin: 0 1.5rem;*/
  /*padding: 1.5rem 0;*/
  /*border-bottom: 1px solid #fff;*/
/*}*/

/*nav {*/
  /*padding: 0.5rem 0;*/
  /*flex: 1 1 auto;*/
/*}*/

/*nav a {*/
  /*display: block;*/
  /*color: #fff;*/
  /*text-decoration: none;*/
  /*font-size: 1.3rem;*/
  /*padding: 0.5rem 1.5rem;*/
  /*position: relative;*/
/*}*/

/*nav a.active::after {*/
  /*content: " ";*/
  /*position: absolute;*/
  /*top: 50%;*/
  /*left: calc(100% - 32px);*/
  /*transform: translateY(-50%);*/
  /*border-width: 16px;*/
  /*border-style: solid;*/
  /*border-color: transparent white transparent transparent;*/
/*}*/


h1,
h2,
h3,
h4,
h5,
h6 {
  margin: 0;
}

h1 {
  font-size: 1.5rem;
}

h2 {
  font-size: 1.4rem;
}

h3 {
  font-size: 1.3rem;
}

h4 {
  font-size: 1.2rem;
}

h5 {
  font-size: 1.1rem;
}

h6 {
  font-size: 1rem;
}

body {
  font-family: Nunito, -apple-system, BlinkMacSystemFont, Segoe UI, Roboto, Oxygen,
    Ubuntu, Cantarell, Fira Sans, Droid Sans, Helvetica Neue, sans-serif;
  color: #333333;
}

.content {
  padding: 1rem;
}

.hide {
  display: none;
}
